import {createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw} from 'vue-router'

const Admin = () => import('@/views/admin/LoginView.vue')
const ForgotPassword = () => import('@/views/admin/system/user/forgot/Password.vue')
const HomeIndex = () => import('@/views/admin/home/Index.vue')
const SendRedirect = () => import('@/views/admin/SendRedirect.vue')
const NotFound = () => import('@/views/error/404.vue')
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'LoginView',
    meta: {
      title: '全国爱心护理工程 - 单点登录管理平台',
      local: 'login.login'
    },
    component: Admin
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: '全国爱心护理工程 - 单点登录管理平台',
      local: 'login.login'
    },
    component: Admin
  },
  {
    path: '/admin/home',
    name: 'Home',
    meta: {
      title: '全国爱心护理工程 - 单点登录管理平台',
      local: 'home.index'
    },
    component: HomeIndex
  },
  {
    path: '/forgot-password',
    name: 'Forgot',
    meta: {
      title: '全国爱心护理工程 - 单点登录管理平台 - 找回密码',
      local: 'login.forgot'
    },
    component: ForgotPassword
  },
  {
    path: '/wechat/login',
    name: 'wechatLogin',
    meta: {
      title: '全国爱心护理工程 - 单点登录管理平台登录跳转',
      local: 'login.login'
    },
    component: SendRedirect
  },
  {
    path: '/404',
    name: 'NotFound',
    meta: {
      title: '404',
      local: 'login.login'
    },
    component: NotFound
  },
]
const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
