const tw = {
  title: '成都譯成科技有限責任公司',
  login: {
    systemName: '单点登录管理中心',
    username: '輸入郵箱地址',
    password: '輸入密碼',
    code: '輸入驗證碼',
    login: '登入',
    register: '註冊用戶',
    forgot: '忘記密碼'
  }
}
export default tw
