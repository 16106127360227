const en = {
  title: 'Chengdu Yicheng Technology Co., Ltd',
  login: {
    systemName: '单点登录管理中心',
    username: 'enter email address',
    password: 'enter password',
    code: 'enter the verification code',
    login: 'Login',
    register: 'registered user',
    forgot: 'forgot password'
  }
}
export default en
